import {
  CLEAR_CUSTOMERS_ERRORS,
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAILURE,
  FETCH_CUSTOMERS_REQUEST,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_FAILURE,
  SAVE_CUSTOMER_REQUEST,
  SAVE_CUSTOMER_SUCCESS,
  SAVE_CUSTOMER_FAILURE,
  DELETE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAILURE,
  SEARCH_CUSTOMERS_REQUEST,
  SEARCH_CUSTOMERS_SUCCESS,
  SEARCH_CUSTOMERS_FAILURE,
  FETCH_CUSTOMER_JOBS_REQUEST,
  FETCH_CUSTOMER_JOBS_SUCCESS,
  FETCH_CUSTOMER_JOBS_FAILURE,
} from 'constants/customers';

const initialState = {
  isFetching: false,
  lastFetchAt: null,
  isSearching: false,
  lastSearchAt: null,
  errors: [],
  filter: '',
  customer: {
    jobs: {
      rows: [],
      page: 0,
      pages: -1,
      pageSize: 10,
      lastFetchAt: null,
    },
  },
  rows: [],
  page: 0,
  pages: -1,
  pageSize: 10,
};

export default function customers(state = initialState, action) {
  switch (action.type) {
    case CLEAR_CUSTOMERS_ERRORS:
      return {
        ...state,
        errors: [],
      };
    case FETCH_CUSTOMER_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case FETCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        customer: action.payload.customer,
        errors: [],
      };
    case FETCH_CUSTOMER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case FETCH_CUSTOMERS_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        rows: action.payload.rows,
        page: action.payload.page,
        pages: action.payload.pages,
        pageSize: action.payload.pageSize,
        filter: action.payload.filter,
        lastFetchAt: new Date(),
        errors: [],
      };
    case FETCH_CUSTOMERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case SAVE_CUSTOMER_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case SAVE_CUSTOMER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        customer: action.payload.customer,
        lastFetchAt: null,
        errors: [],
      };
    case SAVE_CUSTOMER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case DELETE_CUSTOMER_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case DELETE_CUSTOMER_SUCCESS: {
      const rowsState = state.rows.map(customerState => {
        const customer = customerState;
        if (customer.id === action.payload.customerId) {
          customer.deletedAt = new Date();
        }
        return customer;
      });
      return {
        ...state,
        isFetching: false,
        rows: rowsState,
        errors: [],
      };
    }
    case DELETE_CUSTOMER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case SEARCH_CUSTOMERS_REQUEST:
      return {
        ...state,
        isSearching: true,
        errors: [],
      };
    case SEARCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        isSearching: false,
        // customersSearch: action.payload.rows,
        lastSearchAt: new Date(),
        errors: [],
      };
    case SEARCH_CUSTOMERS_FAILURE:
      return {
        ...state,
        isSearching: false,
        errors: action.payload.errors,
      };
    case FETCH_CUSTOMER_JOBS_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case FETCH_CUSTOMER_JOBS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        customer: {
          ...state.customer,
          jobs: {
            rows: action.payload.rows,
            page: action.payload.page,
            pages: action.payload.pages,
            pageSize: action.payload.pageSize,
            lastFetchAt: new Date(),
          },
        },
        lastFetchAt: new Date(),
        errors: [],
      };
    case FETCH_CUSTOMER_JOBS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    default:
      return state;
  }
}
