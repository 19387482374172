/* eslint-disable global-require */

import { isShopRoute, setPageTitle, toSiteRoute } from './middleware';

// constructor for route load source with chunk name
const Load = source => ({
  load: () =>
    import(
      /* webpackChunkName: "[request]" */
      `./${source}`
    ),
});

const shopRoutes = {
  path: '',
  action: isShopRoute(),
  children: [
    {
      path: '',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/dashboard'),
    },
    {
      path: '/bench',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/bench'),
    },
    {
      path: '/schedule',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/schedule'),
    },
    {
      path: '/schedule/:date',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/schedule'),
    },
    {
      path: '/jobs',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/jobs'),
    },
    {
      path: '/job/new',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/job/edit'),
    },
    {
      path: '/job/new/:customerId',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/job/edit'),
    },
    {
      path: '/job/:id/edit',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/job/edit'),
    },
    {
      path: '/job/:id/print',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/job/print-workorder'),
    },
    {
      path: '/job/:id/invoice/print',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/job/print-invoice'),
    },
    {
      path: '/job/:id',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/job/view'),
    },
    {
      path: '/customers',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/customers'),
    },
    {
      path: '/customer/new',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/customer/edit'),
    },
    {
      path: '/customer/:id/edit',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/customer/edit'),
    },
    {
      path: '/customer/:id',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/customer/view'),
    },
    {
      path: '/products',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/products'),
    },
    {
      path: '/product/new',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/product-edit'),
    },
    {
      path: '/product/:id/edit',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/product-edit'),
    },
    {
      path: '/services',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/services'),
    },
    {
      path: '/service/new',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/service-edit'),
    },
    {
      path: '/service/:id/edit',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/service-edit'),
    },
    {
      path: '/techs',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/techs'),
    },
    {
      path: '/tech/invite',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/tech/invite'),
    },
    {
      path: '/tech/:id',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/tech/view'),
    },
    {
      path: '/settings',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/settings'),
    },
    {
      path: '/upgrade',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/upgrade'),
    },
    {
      path: '/subscription',
      requireLogin: true,
      requireShopId: true,
      ...Load('shop/subscription'),
    },
    {
      path: '/forgot',
      requireShopId: true,
      ...Load('shop/forgot'),
    },
    {
      path: '/reset/:token',
      requireShopId: true,
      ...Load('shop/reset'),
    },
    {
      path: '/invite/:token',
      requireShopId: true,
      ...Load('shop/invite'),
    },
    {
      path: '',
      requireShopId: true,
      ...Load('shop/sign-in'),
    },
    {
      path: '/login/:token',
      requireShopId: true,
      ...Load('shop/login'),
    },
    {
      path: '/s/(.*)',
      action: toSiteRoute,
    },
    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      ...Load('shop/not-found'),
    },
  ],
};

const siteRoutes = {
  path: '',
  action: isShopRoute(false),
  children: [
    {
      // site home
      path: '',
      ...Load('site/home'),
    },
    {
      // get started to find/create a shop
      path: '/get-started',
      ...Load('auth/get-started'),
    },
    {
      // create shop step 1
      path: '/create',
      ...Load('auth/create'),
    },
    {
      // create shop step 2
      path: '/create/confirm',
      ...Load('auth/create/confirm'),
    },
    {
      // create shop step 3
      path: '/create/new',
      ...Load('auth/create/new'),
    },
    {
      // create shop step 4
      path: '/create/invite',
      ...Load('auth/create/invite'),
    },
    {
      // create shop step 5
      path: '/create/success',
      ...Load('auth/create/success'),
    },
    {
      // find shop
      path: '/find',
      ...Load('auth/find'),
    },
    {
      // view logged in users shops
      path: '/your-workshops',
      ...Load('auth/your-workshops'),
    },
    {
      // view findToken's associated accounts' shops
      path: '/your-workshops/:token',
      ...Load('auth/your-workshops'),
    },
    {
      // site to shop domain sign-in
      path: '/signin',
      ...Load('auth/signin'),
    },
    {
      // view findToken's associated accounts' shops
      path: '/email/settings/:ehash',
      ...Load('auth/email-settings'),
    },
    {
      path: '/privacy',
      ...Load('site/privacy'),
    },
    {
      path: '/terms',
      ...Load('site/terms'),
    },
    // {
    //   path: '/about',
    //   ...Load('site/about'),
    // },
    // {
    //   path: '/contact',
    //   ...Load('site/contact'),
    // },
    // Route({
    //   middleware: [middlewareA, middlewareB, middlewareC],
    //   path: '/admin',
    //   requireLogin: true,
    //   ...Load('admin'),
    // }),
  ],
};

// The top-level (parent) route
const routes = {
  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    shopRoutes,
    siteRoutes,
    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      load: () =>
        import(/* webpackChunkName: 'not-found' */ './site/not-found'),
    },
  ],

  action: setPageTitle,
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./site/error').default,
  });
}

export default routes;
