export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const DISABLE_USER_REQUEST = 'DISABLE_USER_REQUEST';
export const DISABLE_USER_SUCCESS = 'DISABLE_USER_SUCCESS';
export const DISABLE_USER_FAILURE = 'DISABLE_USER_FAILURE';

export const ENABLE_USER_REQUEST = 'ENABLE_USER_REQUEST';
export const ENABLE_USER_SUCCESS = 'ENABLE_USER_SUCCESS';
export const ENABLE_USER_FAILURE = 'ENABLE_USER_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const FETCH_TECH_JOBS_REQUEST = 'FETCH_TECH_JOBS_REQUEST';
export const FETCH_TECH_JOBS_SUCCESS = 'FETCH_TECH_JOBS_SUCCESS';
export const FETCH_TECH_JOBS_FAILURE = 'FETCH_TECH_JOBS_FAILURE';

export const SEARCH_TECHS_REQUEST = 'SEARCH_TECHS_REQUEST';
export const SEARCH_TECHS_SUCCESS = 'SEARCH_TECHS_SUCCESS';
export const SEARCH_TECHS_FAILURE = 'SEARCH_TECHS_FAILURE';
