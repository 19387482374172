export const GET_STARTED_REQUEST = 'GET_STARTED_REQUEST';
export const GET_STARTED_SUCCESS = 'GET_STARTED_SUCCESS';
export const GET_STARTED_FAILURE = 'GET_STARTED_FAILURE';
export const CREATE_SHOP_USER_REQUEST = 'CREATE_SHOP_USER_REQUEST';
export const CREATE_SHOP_USER_SUCCESS = 'CREATE_SHOP_USER_SUCCESS';
export const CREATE_SHOP_USER_FAILURE = 'CREATE_SHOP_USER_FAILURE';
export const CONFIRM_SHOP_USER_REQUEST = 'CONFIRM_SHOP_USER_REQUEST';
export const CONFIRM_SHOP_USER_SUCCESS = 'CONFIRM_SHOP_USER_SUCCESS';
export const CONFIRM_SHOP_USER_FAILURE = 'CONFIRM_SHOP_USER_FAILURE';
export const CREATE_SHOP_REQUEST = 'CREATE_SHOP_REQUEST';
export const CREATE_SHOP_SUCCESS = 'CREATE_SHOP_SUCCESS';
export const CREATE_SHOP_FAILURE = 'CREATE_SHOP_FAILURE';
export const CREATE_INVITE_REQUEST = 'CREATE_INVITE_REQUEST';
export const CREATE_INVITE_SUCCESS = 'CREATE_INVITE_SUCCESS';
export const CREATE_INVITE_FAILURE = 'CREATE_INVITE_FAILURE';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const SIGN_OUT_REQUEST = 'SIGN_OUT_REQUEST';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const ACCEPT_INVITE_REQUEST = 'ACCEPT_INVITE_REQUEST';
export const ACCEPT_INVITE_SUCCESS = 'ACCEPT_INVITE_SUCCESS';
export const ACCEPT_INVITE_FAILURE = 'ACCEPT_INVITE_FAILURE';
export const CHECK_INVITE_TOKEN_REQUEST = 'CHECK_INVITE_TOKEN_REQUEST';
export const CHECK_INVITE_TOKEN_SUCCESS = 'CHECK_INVITE_TOKEN_SUCCESS';
export const CHECK_INVITE_TOKEN_FAILURE = 'CHECK_INVITE_TOKEN_FAILURE';
export const FIND_SHOP_REQUEST = 'FIND_SHOP_REQUEST';
export const FIND_SHOP_SUCCESS = 'FIND_SHOP_SUCCESS';
export const FIND_SHOP_FAILURE = 'FIND_SHOP_FAILURE';
export const CHECK_FIND_TOKEN_REQUEST = 'CHECK_FIND_TOKEN_REQUEST';
export const CHECK_FIND_TOKEN_SUCCESS = 'CHECK_FIND_TOKEN_SUCCESS';
export const CHECK_FIND_TOKEN_FAILURE = 'CHECK_FIND_TOKEN_FAILURE';
export const CHECK_EMAIL_HASH_REQUEST = 'CHECK_EMAIL_HASH_REQUEST';
export const CHECK_EMAIL_HASH_SUCCESS = 'CHECK_EMAIL_HASH_SUCCESS';
export const CHECK_EMAIL_HASH_FAILURE = 'CHECK_EMAIL_HASH_FAILURE';
export const UPDATE_EMAIL_SETTINGS_REQUEST = 'UPDATE_EMAIL_SETTINGS_REQUEST';
export const UPDATE_EMAIL_SETTINGS_SUCCESS = 'UPDATE_EMAIL_SETTINGS_SUCCESS';
export const UPDATE_EMAIL_SETTINGS_FAILURE = 'UPDATE_EMAIL_SETTINGS_FAILURE';
export const CHECK_SHOP_NAME_REQUEST = 'CHECK_SHOP_NAME_REQUEST';
export const CHECK_SHOP_NAME_SUCCESS = 'CHECK_SHOP_NAME_SUCCESS';
export const CHECK_SHOP_NAME_FAILURE = 'CHECK_SHOP_NAME_FAILURE';
