import {
  GET_STARTED_REQUEST,
  GET_STARTED_SUCCESS,
  GET_STARTED_FAILURE,
  CREATE_SHOP_USER_REQUEST,
  CREATE_SHOP_USER_SUCCESS,
  CREATE_SHOP_USER_FAILURE,
  CONFIRM_SHOP_USER_REQUEST,
  CONFIRM_SHOP_USER_SUCCESS,
  CONFIRM_SHOP_USER_FAILURE,
  CREATE_SHOP_REQUEST,
  CREATE_SHOP_SUCCESS,
  CREATE_SHOP_FAILURE,
  CREATE_INVITE_REQUEST,
  CREATE_INVITE_SUCCESS,
  CREATE_INVITE_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
  CLEAR_AUTH_ERRORS,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  ACCEPT_INVITE_REQUEST,
  ACCEPT_INVITE_SUCCESS,
  ACCEPT_INVITE_FAILURE,
  FIND_SHOP_REQUEST,
  FIND_SHOP_SUCCESS,
  FIND_SHOP_FAILURE,
  CHECK_FIND_TOKEN_REQUEST,
  CHECK_FIND_TOKEN_SUCCESS,
  CHECK_FIND_TOKEN_FAILURE,
  UPDATE_EMAIL_SETTINGS_REQUEST,
  UPDATE_EMAIL_SETTINGS_SUCCESS,
  UPDATE_EMAIL_SETTINGS_FAILURE,
  CHECK_EMAIL_HASH_REQUEST,
  CHECK_EMAIL_HASH_SUCCESS,
  CHECK_EMAIL_HASH_FAILURE,
  CHECK_SHOP_NAME_REQUEST,
  CHECK_SHOP_NAME_SUCCESS,
  CHECK_SHOP_NAME_FAILURE,
} from 'constants/auth';

const initialState = {
  isFetching: false,
  email: '',
  user: {
    shop: {},
  },
  token: null,
  errors: [],
  resetSent: false,
  resetDone: false,
  findToken: null,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case GET_STARTED_REQUEST:
      return {
        ...state,
        email: action.payload.email,
      };
    case GET_STARTED_SUCCESS:
      return {
        ...state,
      };
    case GET_STARTED_FAILURE:
      return {
        ...state,
      };
    case CREATE_SHOP_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
        email: action.payload.email,
        errors: [],
      };
    case CREATE_SHOP_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.payload.user,
        token: action.payload.token,
        errors: [],
      };
    case CREATE_SHOP_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case CONFIRM_SHOP_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case CONFIRM_SHOP_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.payload.user,
        token: action.payload.token,
        errors: [],
      };
    case CONFIRM_SHOP_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case CREATE_SHOP_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case CREATE_SHOP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.payload.user,
        token: action.payload.token,
        errors: [],
      };
    case CREATE_SHOP_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case CREATE_INVITE_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case CREATE_INVITE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errors: [],
      };
    case CREATE_INVITE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.payload.user,
        token: action.payload.token,
        errors: [],
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case SIGN_IN_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.payload.user,
        token: action.payload.token,
        errors: [],
      };
    case SIGN_IN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case SIGN_OUT_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case SIGN_OUT_SUCCESS:
      return initialState;
    case CLEAR_AUTH_ERRORS:
      return {
        ...state,
        resetSent: false,
        resetDone: false,
        errors: [],
      };
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        resetSent: true,
        errors: [],
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        resetDone: true,
        errors: [],
        user: action.payload.user,
        token: action.payload.token,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case ACCEPT_INVITE_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case ACCEPT_INVITE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errors: [],
        user: action.payload.user,
        token: action.payload.token,
      };
    case ACCEPT_INVITE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case FIND_SHOP_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case FIND_SHOP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errors: [],
      };
    case FIND_SHOP_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case CHECK_FIND_TOKEN_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case CHECK_FIND_TOKEN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        findToken: action.payload.token,
        errors: [],
      };
    case CHECK_FIND_TOKEN_FAILURE:
      return {
        ...state,
        isFetching: false,
        findToken: null,
        errors: action.payload.errors,
      };
    case UPDATE_EMAIL_SETTINGS_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case UPDATE_EMAIL_SETTINGS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errors: [],
      };
    case UPDATE_EMAIL_SETTINGS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case CHECK_EMAIL_HASH_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case CHECK_EMAIL_HASH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errors: [],
      };
    case CHECK_EMAIL_HASH_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case CHECK_SHOP_NAME_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case CHECK_SHOP_NAME_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errors: [],
      };
    case CHECK_SHOP_NAME_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    default:
      return state;
  }
}
