import {
  CLEAR_PRODUCTS_ERRORS,
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE,
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
  SAVE_PRODUCT_REQUEST,
  SAVE_PRODUCT_SUCCESS,
  SAVE_PRODUCT_FAILURE,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  SEARCH_PRODUCTS_REQUEST,
  SEARCH_PRODUCTS_SUCCESS,
  SEARCH_PRODUCTS_FAILURE,
} from 'constants/products';

const initialState = {
  isFetching: false,
  lastFetchAt: null,
  isSearching: false,
  lastSearchAt: null,
  errors: [],
  filter: '',
  product: {},
  rows: [],
  page: 0,
  pages: -1,
  pageSize: 10,
};

export default function products(state = initialState, action) {
  switch (action.type) {
    case CLEAR_PRODUCTS_ERRORS:
      return {
        ...state,
        errors: [],
      };
    case FETCH_PRODUCT_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        product: action.payload.product,
        errors: [],
      };
    case FETCH_PRODUCT_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        rows: action.payload.rows,
        page: action.payload.page,
        pages: action.payload.pages,
        pageSize: action.payload.pageSize,
        filter: action.payload.filter,
        lastFetchAt: new Date(),
        errors: [],
      };
    case FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case SAVE_PRODUCT_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case SAVE_PRODUCT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        product: action.payload.product,
        lastFetchAt: null,
        errors: [],
      };
    case SAVE_PRODUCT_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case DELETE_PRODUCT_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case DELETE_PRODUCT_SUCCESS: {
      const rowsState = state.rows.map(productState => {
        const product = productState;
        if (product.id === action.payload.productId) {
          product.deletedAt = new Date();
        }
        return product;
      });
      return {
        ...state,
        isFetching: false,
        rows: rowsState,
        errors: [],
      };
    }
    case DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case SEARCH_PRODUCTS_REQUEST:
      return {
        ...state,
        isSearching: true,
        errors: [],
      };
    case SEARCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        isSearching: false,
        // productsSearch: action.payload.rows,
        lastSearchAt: new Date(),
        errors: [],
      };
    case SEARCH_PRODUCTS_FAILURE:
      return {
        ...state,
        isSearching: false,
        errors: action.payload.errors,
      };
    default:
      return state;
  }
}
