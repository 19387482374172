import {
  CLEAR_SHOP_ERRORS,
  UPDATE_SHOP_REQUEST,
  UPDATE_SHOP_SUCCESS,
  UPDATE_SHOP_FAILURE,
  FETCH_SHOP_STATS_REQUEST,
  FETCH_SHOP_STATS_SUCCESS,
  FETCH_SHOP_STATS_FAILURE,
  FETCH_SHOP_SETTINGS_REQUEST,
  FETCH_SHOP_SETTINGS_SUCCESS,
  FETCH_SHOP_SETTINGS_FAILURE,
  CREATE_SUBSCRIPTION_REQUEST,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAILURE,
  FETCH_SUBSCRIPTION_REQUEST,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_SUBSCRIPTION_FAILURE,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  REACTIVATE_SUBSCRIPTION_REQUEST,
  REACTIVATE_SUBSCRIPTION_SUCCESS,
  REACTIVATE_SUBSCRIPTION_FAILURE,
} from 'constants/shop';

const initialState = {
  id: null,
  name: null,
  subdomain: null,
  activated: false,
  isFetching: false,
  subscription: null,
  stats: {
    jobCount: 0,
    inShopCount: 0,
    onSiteCount: 0,
    customerCount: 0,
  },
  meta: {
    addressStreet: '',
    addressCity: '',
    addressState: '',
    addressZip: '',
    phonePrimary: '',
    invoiceAgreement: '',
  },
  errors: [],
};

export default function shop(state = initialState, action) {
  switch (action.type) {
    case CLEAR_SHOP_ERRORS:
      return {
        ...state,
        errors: [],
      };
    case UPDATE_SHOP_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case UPDATE_SHOP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        name: action.payload.name,
        subdomain: action.payload.subdomain,
        activated: action.payload.activated,
      };
    case UPDATE_SHOP_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case FETCH_SHOP_STATS_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case FETCH_SHOP_STATS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        stats: action.payload.stats,
      };
    case FETCH_SHOP_STATS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case CREATE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        ...action.payload.shop,
        // isFetching: false,
      };
    case CREATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case FETCH_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case FETCH_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        subscription: action.payload.subscription,
      };
    case FETCH_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case CANCEL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          ...action.payload.subscription,
        },
        isFetching: false,
      };
    case CANCEL_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case REACTIVATE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case REACTIVATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          ...action.payload.subscription,
        },
        isFetching: false,
      };
    case REACTIVATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case FETCH_SHOP_SETTINGS_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case FETCH_SHOP_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.payload.shop,
        meta: action.payload.meta,
        subscription: action.payload.subscription || state.subscription,
        isFetching: false,
      };
    case FETCH_SHOP_SETTINGS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    default:
      return state;
  }
}
