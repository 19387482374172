import UniversalRouter from 'universal-router';
import routes from './routes';

export default new UniversalRouter(routes, {
  resolveRoute(context, params) {
    const { pathname, route, store } = context;

    // require shop id middleware
    if (route.requireShopId) {
      const { shop } = store.getState();
      if (!shop || !shop.id) {
        return null;
      }
    }
    // require login middleware
    if (route.requireLogin) {
      const {
        auth: { user },
        shop,
      } = store.getState();

      if (
        // must have a user object with id
        !user ||
        !user.id ||
        // must have user with matching shop id
        user.shopId !== shop.id
      ) {
        if (pathname === '/') {
          return null;
        }

        return { redirect: '/' };
      }
    }
    // resolve route
    if (typeof route.load === 'function') {
      return route.load().then(action => action.default(context, params));
    }
    if (typeof route.action === 'function') {
      return route.action(context, params);
    }
    return undefined;
  },
});
