import {
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  FORGOT_REQUEST,
  FORGOT_SUCCESS,
  FORGOT_FAILURE,
  RESET_REQUEST,
  RESET_SUCCESS,
  RESET_FAILURE,
  CLEAR_AUTH_ERRORS,
  EMAIL_VERIFY_FAILURE,
  RESEND_VERIFY_REQUEST,
  RESEND_VERIFY_SUCCESS,
  RESEND_VERIFY_FAILURE,
} from '../constants/site';

const initialState = {
  isFetching: false,
  user: {},
  token: null,
  errors: [],
};

export default function site(state = initialState, action) {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.payload.user,
        token: action.payload.token,
        errors: [],
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.payload.user,
        token: action.payload.token,
        errors: [],
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };

    case LOGOUT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case LOGOUT_SUCCESS:
      return initialState;

    case FORGOT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FORGOT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        resetSent: true,
        errors: [],
      };
    case FORGOT_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };

    case RESET_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case RESET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        resetDone: true,
        errors: [],
      };
    case RESET_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case CLEAR_AUTH_ERRORS:
      return {
        ...state,
        errors: [],
      };
    case EMAIL_VERIFY_FAILURE:
      return {
        ...state,
        errors: action.payload.errors,
      };
    case RESEND_VERIFY_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case RESEND_VERIFY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errors: [],
        user: {
          ...state.user,
          verified: action.payload.user.verified,
        },
      };
    case RESEND_VERIFY_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    default:
      return state;
  }
}
