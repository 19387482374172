export const CLEAR_SHOP_ERRORS = 'CLEAR_SHOP_ERRORS';
export const UPDATE_SHOP_REQUEST = 'UPDATE_SHOP_REQUEST';
export const UPDATE_SHOP_SUCCESS = 'UPDATE_SHOP_SUCCESS';
export const UPDATE_SHOP_FAILURE = 'UPDATE_SHOP_FAILURE';
export const UPDATE_META_REQUEST = 'UPDATE_META_REQUEST';
export const UPDATE_META_SUCCESS = 'UPDATE_META_SUCCESS';
export const UPDATE_META_FAILURE = 'UPDATE_META_FAILURE';
export const FETCH_SHOP_STATS_REQUEST = 'FETCH_SHOP_STATS_REQUEST';
export const FETCH_SHOP_STATS_SUCCESS = 'FETCH_SHOP_STATS_SUCCESS';
export const FETCH_SHOP_STATS_FAILURE = 'FETCH_SHOP_STATS_FAILURE';
export const FETCH_SHOP_SETTINGS_REQUEST = 'FETCH_SHOP_SETTINGS_REQUEST';
export const FETCH_SHOP_SETTINGS_SUCCESS = 'FETCH_SHOP_SETTINGS_SUCCESS';
export const FETCH_SHOP_SETTINGS_FAILURE = 'FETCH_SHOP_SETTINGS_FAILURE';
export const CREATE_SUBSCRIPTION_REQUEST = 'CREATE_SUBSCRIPTION_REQUEST';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_FAILURE = 'CREATE_SUBSCRIPTION_FAILURE';
export const FETCH_SUBSCRIPTION_REQUEST = 'FETCH_SUBSCRIPTION_REQUEST';
export const FETCH_SUBSCRIPTION_SUCCESS = 'FETCH_SUBSCRIPTION_SUCCESS';
export const FETCH_SUBSCRIPTION_FAILURE = 'FETCH_SUBSCRIPTION_FAILURE';
export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE';
export const REACTIVATE_SUBSCRIPTION_REQUEST =
  'REACTIVATE_SUBSCRIPTION_REQUEST';
export const REACTIVATE_SUBSCRIPTION_SUCCESS =
  'REACTIVATE_SUBSCRIPTION_SUCCESS';
export const REACTIVATE_SUBSCRIPTION_FAILURE =
  'REACTIVATE_SUBSCRIPTION_FAILURE';
