export const CLEAR_JOBS_ERRORS = 'CLEAR_JOBS_ERRORS';
export const FETCH_JOB_REQUEST = 'FETCH_JOB_REQUEST';
export const FETCH_JOB_SUCCESS = 'FETCH_JOB_SUCCESS';
export const FETCH_JOB_FAILURE = 'FETCH_JOB_FAILURE';
export const FETCH_JOBS_REQUEST = 'FETCH_JOBS_REQUEST';
export const FETCH_JOBS_SUCCESS = 'FETCH_JOBS_SUCCESS';
export const FETCH_JOBS_FAILURE = 'FETCH_JOBS_FAILURE';
export const SAVE_JOB_REQUEST = 'SAVE_JOB_REQUEST';
export const SAVE_JOB_SUCCESS = 'SAVE_JOB_SUCCESS';
export const SAVE_JOB_FAILURE = 'SAVE_JOB_FAILURE';
export const DELETE_JOB_REQUEST = 'DELETE_JOB_REQUEST';
export const DELETE_JOB_SUCCESS = 'DELETE_JOB_SUCCESS';
export const DELETE_JOB_FAILURE = 'DELETE_JOB_FAILURE';
export const FETCH_SCHEDULED_JOBS_REQUEST = 'FETCH_SCHEDULED_JOBS_REQUEST';
export const FETCH_SCHEDULED_JOBS_SUCCESS = 'FETCH_SCHEDULED_JOBS_SUCCESS';
export const FETCH_SCHEDULED_JOBS_FAILURE = 'FETCH_SCHEDULED_JOBS_FAILURE';
export const FETCH_BENCHED_JOBS_REQUEST = 'FETCH_BENCHED_JOBS_REQUEST';
export const FETCH_BENCHED_JOBS_SUCCESS = 'FETCH_BENCHED_JOBS_SUCCESS';
export const FETCH_BENCHED_JOBS_FAILURE = 'FETCH_BENCHED_JOBS_FAILURE';
export const ADD_PAYMENT_REQUEST = 'ADD_PAYMENT_REQUEST';
export const ADD_PAYMENT_SUCCESS = 'ADD_PAYMENT_SUCCESS';
export const ADD_PAYMENT_FAILURE = 'ADD_PAYMENT_FAILURE';
export const DELETE_PAYMENT_REQUEST = 'DELETE_PAYMENT_REQUEST';
export const DELETE_PAYMENT_SUCCESS = 'DELETE_PAYMENT_SUCCESS';
export const DELETE_PAYMENT_FAILURE = 'DELETE_PAYMENT_FAILURE';

export const SOURCE_OPTIONS = [
  { value: 'billboard', label: 'Billboard' },
  { value: 'car-wrap', label: 'Car Wrap' },
  { value: 'craigslist', label: 'Craigslist' },
  { value: 'facebook', label: 'Facebook' },
  { value: 'flyer', label: 'Flyer' },
  { value: 'google-search', label: 'Google Search' },
  { value: 'phone-book', label: 'Phone Book' },
  { value: 'post-card', label: 'Post Card' },
  { value: 'referral', label: 'Referral' },
  { value: 'tv-commercial', label: 'TV Commercial' },
  { value: 'youtube', label: 'YouTube' },
  { value: 'website', label: 'Website' },
];
