import {
  CLEAR_SERVICES_ERRORS,
  FETCH_SERVICE_REQUEST,
  FETCH_SERVICE_SUCCESS,
  FETCH_SERVICE_FAILURE,
  FETCH_SERVICES_REQUEST,
  FETCH_SERVICES_SUCCESS,
  FETCH_SERVICES_FAILURE,
  SAVE_SERVICE_REQUEST,
  SAVE_SERVICE_SUCCESS,
  SAVE_SERVICE_FAILURE,
  DELETE_SERVICE_REQUEST,
  DELETE_SERVICE_SUCCESS,
  DELETE_SERVICE_FAILURE,
  SEARCH_SERVICES_REQUEST,
  SEARCH_SERVICES_SUCCESS,
  SEARCH_SERVICES_FAILURE,
} from 'constants/services';

const initialState = {
  isFetching: false,
  lastFetchAt: null,
  isSearching: false,
  lastSearchAt: null,
  errors: [],
  filter: '',
  service: {},
  rows: [],
  page: 0,
  pages: -1,
  pageSize: 10,
};

export default function services(state = initialState, action) {
  switch (action.type) {
    case CLEAR_SERVICES_ERRORS:
      return {
        ...state,
        errors: [],
      };
    case FETCH_SERVICE_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case FETCH_SERVICE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        service: action.payload.service,
        errors: [],
      };
    case FETCH_SERVICE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case FETCH_SERVICES_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case FETCH_SERVICES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        rows: action.payload.rows,
        page: action.payload.page,
        pages: action.payload.pages,
        pageSize: action.payload.pageSize,
        filter: action.payload.filter,
        lastFetchAt: new Date(),
        errors: [],
      };
    case FETCH_SERVICES_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case SAVE_SERVICE_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case SAVE_SERVICE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        service: action.payload.service,
        lastFetchAt: null,
        errors: [],
      };
    case SAVE_SERVICE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case DELETE_SERVICE_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case DELETE_SERVICE_SUCCESS: {
      const rowsState = state.rows.map(serviceState => {
        const service = serviceState;
        if (service.id === action.payload.serviceId) {
          service.deletedAt = new Date();
        }
        return service;
      });
      return {
        ...state,
        isFetching: false,
        rows: rowsState,
        errors: [],
      };
    }
    case DELETE_SERVICE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case SEARCH_SERVICES_REQUEST:
      return {
        ...state,
        isSearching: true,
        errors: [],
      };
    case SEARCH_SERVICES_SUCCESS:
      return {
        ...state,
        isSearching: false,
        // servicesSearch: action.payload.rows,
        lastSearchAt: new Date(),
        errors: [],
      };
    case SEARCH_SERVICES_FAILURE:
      return {
        ...state,
        isSearching: false,
        errors: action.payload.errors,
      };
    default:
      return state;
  }
}
