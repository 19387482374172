import {
  FETCH_INVITES_REQUEST,
  FETCH_INVITES_SUCCESS,
  FETCH_INVITES_FAILURE,
  CANCEL_INVITE_REQUEST,
  CANCEL_INVITE_SUCCESS,
  CANCEL_INVITE_FAILURE,
} from 'constants/invites';

const initialState = {
  rows: [],
  page: 0,
  pages: -1,
  pageSize: 5,
  errors: [],
  filter: '',
  isFetching: false,
  lastFetchAt: null,
};

export default function invites(state = initialState, action) {
  switch (action.type) {
    case FETCH_INVITES_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case FETCH_INVITES_SUCCESS: {
      const { lastFetchAt } = state;
      const { update } = action.payload;
      return {
        ...state,
        isFetching: false,
        rows: action.payload.rows,
        page: action.payload.page,
        pages: action.payload.pages,
        pageSize: action.payload.pageSize,
        filter: action.payload.filter,
        errors: [],
        lastFetchAt: update ? new Date() : lastFetchAt,
      };
    }
    case FETCH_INVITES_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case CANCEL_INVITE_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case CANCEL_INVITE_SUCCESS: {
      const rowsState = state.rows.map(inviteState => {
        const invite = inviteState;
        if (invite.id === action.payload.inviteId) {
          invite.deletedAt = new Date();
        }
        return invite;
      });
      return {
        ...state,
        isFetching: false,
        rows: rowsState,
        errors: [],
      };
    }
    case CANCEL_INVITE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    default:
      return state;
  }
}
