import { combineReducers } from 'redux';

import auth from './auth';
import customers from './customers';
import intl from './intl';
import jobs from './jobs';
import products from './products';
import runtime from './runtime';
import services from './services';
import shop from './shop';
import site from './site';
import techs from './techs';
import invites from './invites';
import user from './user';

export default combineReducers({
  auth,
  customers,
  intl,
  jobs,
  products,
  runtime,
  services,
  shop,
  site,
  techs,
  invites,
  user,
});
