// Universal-Router Action Middlewares

const allowSubdomain = async ({ next, subdomain }) => {
  if (!subdomain) return null;

  const route = await next();

  return route;
};

const disallowSubdomain = async ({ next, subdomain }) => {
  if (subdomain) return null;

  const route = await next();

  return route;
};

const isShopRoute = (isShop = true) => async context => {
  const { next, siteUrl, pathname, subdomain } = context;

  // Not Found
  if (isShop && !subdomain) return null;

  if (!isShop && subdomain) {
    // No route found.
    if (!context.route) return null;
    // Redirect to found site route.
    return { redirect: `${siteUrl}${pathname}` };
  }

  const route = await next();

  return route;
};

const toSiteRoute = async ({ siteUrl, pathname }) => ({
  redirect: `${siteUrl}${pathname.replace('/s', '')}`,
});

const setPageTitle = async ({ next }) => {
  // Execute each child route until one of them return the result
  const route = await next();

  // Provide default values for title, description etc.
  route.title = `${route.title ? `${route.title} | ` : ''}Techsbox`;
  route.description = route.description || '';

  return route;
};

// some example middlewares
const middlewareA = async ({ next }) => {
  const route = await next();
  route.title = `${route.title} A`;
  return route;
};

const middlewareB = async ({ next }) => {
  const route = await next();
  route.title = `${route.title}B`;
  return route;
};

const middlewareC = async ({ next }) => {
  const route = await next();
  route.title = `${route.title}C`;
  return route;
};

export {
  allowSubdomain,
  disallowSubdomain,
  isShopRoute,
  toSiteRoute,
  setPageTitle,
  middlewareA,
  middlewareB,
  middlewareC,
};
