import {
  CLEAR_JOBS_ERRORS,
  FETCH_JOB_REQUEST,
  FETCH_JOB_SUCCESS,
  FETCH_JOB_FAILURE,
  FETCH_JOBS_REQUEST,
  FETCH_JOBS_SUCCESS,
  FETCH_JOBS_FAILURE,
  SAVE_JOB_REQUEST,
  SAVE_JOB_SUCCESS,
  SAVE_JOB_FAILURE,
  DELETE_JOB_REQUEST,
  DELETE_JOB_SUCCESS,
  DELETE_JOB_FAILURE,
  FETCH_SCHEDULED_JOBS_REQUEST,
  FETCH_SCHEDULED_JOBS_SUCCESS,
  FETCH_SCHEDULED_JOBS_FAILURE,
  FETCH_BENCHED_JOBS_REQUEST,
  FETCH_BENCHED_JOBS_SUCCESS,
  FETCH_BENCHED_JOBS_FAILURE,
  ADD_PAYMENT_REQUEST,
  ADD_PAYMENT_SUCCESS,
  ADD_PAYMENT_FAILURE,
  DELETE_PAYMENT_REQUEST,
  DELETE_PAYMENT_SUCCESS,
  DELETE_PAYMENT_FAILURE,
} from 'constants/jobs';

const initialState = {
  isFetching: false,
  lastFetchAt: null,
  errors: [],
  filter: '',
  job: {},
  rows: [],
  page: 0,
  pages: -1,
  pageSize: 10,
  benches: [],
  schedule: [],
};

export default function jobs(state = initialState, action) {
  switch (action.type) {
    case CLEAR_JOBS_ERRORS:
      return {
        ...state,
        errors: [],
      };
    case FETCH_JOB_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case FETCH_JOB_SUCCESS:
      return {
        ...state,
        isFetching: false,
        job: action.payload.job,
        errors: [],
      };
    case FETCH_JOB_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case FETCH_JOBS_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case FETCH_JOBS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        rows: action.payload.rows,
        page: action.payload.page,
        pages: action.payload.pages,
        pageSize: action.payload.pageSize,
        filter: action.payload.filter,
        lastFetchAt: new Date(),
        errors: [],
      };
    case FETCH_JOBS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case SAVE_JOB_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case SAVE_JOB_SUCCESS:
      return {
        ...state,
        isFetching: false,
        job: action.payload.job,
        lastFetchAt: null,
        errors: [],
      };
    case SAVE_JOB_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case DELETE_JOB_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case DELETE_JOB_SUCCESS: {
      const rowsState = state.rows.map(jobState => {
        const job = jobState;
        if (job.id === action.payload.jobId) {
          job.deletedAt = new Date();
        }
        return job;
      });
      return {
        ...state,
        isFetching: false,
        rows: rowsState,
        errors: [],
      };
    }
    case DELETE_JOB_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case FETCH_SCHEDULED_JOBS_REQUEST:
      return {
        ...state,
        isFetching: true,
        schedule: [],
        errors: [],
      };
    case FETCH_SCHEDULED_JOBS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        schedule: action.payload.rows,
        errors: [],
      };
    case FETCH_SCHEDULED_JOBS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case FETCH_BENCHED_JOBS_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case FETCH_BENCHED_JOBS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        benches: action.payload.rows,
        errors: [],
      };
    case FETCH_BENCHED_JOBS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case ADD_PAYMENT_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case ADD_PAYMENT_SUCCESS:
      return {
        ...state,
        job: {
          ...state.job,
          invoice: action.payload.invoice,
        },
        isFetching: false,
        errors: [],
      };
    case ADD_PAYMENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case DELETE_PAYMENT_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case DELETE_PAYMENT_SUCCESS:
      return {
        ...state,
        job: {
          ...state.job,
          invoice: action.payload.invoice,
        },
        isFetching: false,
        errors: [],
      };
    case DELETE_PAYMENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    default:
      return state;
  }
}
