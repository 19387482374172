export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const FORGOT_REQUEST = 'FORGOT_REQUEST';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_FAILURE = 'FORGOT_FAILURE';

export const RESET_REQUEST = 'RESET_REQUEST';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_FAILURE = 'RESET_FAILURE';

export const CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS';

export const EMAIL_VERIFY_FAILURE = 'EMAIL_VERIFY_FAILURE';

export const RESEND_VERIFY_REQUEST = 'RESEND_VERIFY_REQUEST';
export const RESEND_VERIFY_SUCCESS = 'RESEND_VERIFY_SUCCESS';
export const RESEND_VERIFY_FAILURE = 'RESEND_VERIFY_FAILURE';
