export const CLEAR_SERVICES_ERRORS = 'CLEAR_SERVICES_ERRORS';
export const FETCH_SERVICE_REQUEST = 'FETCH_SERVICE_REQUEST';
export const FETCH_SERVICE_SUCCESS = 'FETCH_SERVICE_SUCCESS';
export const FETCH_SERVICE_FAILURE = 'FETCH_SERVICE_FAILURE';
export const FETCH_SERVICES_REQUEST = 'FETCH_SERVICES_REQUEST';
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICES_FAILURE = 'FETCH_SERVICES_FAILURE';
export const SAVE_SERVICE_REQUEST = 'SAVE_SERVICE_REQUEST';
export const SAVE_SERVICE_SUCCESS = 'SAVE_SERVICE_SUCCESS';
export const SAVE_SERVICE_FAILURE = 'SAVE_SERVICE_FAILURE';
export const DELETE_SERVICE_REQUEST = 'DELETE_SERVICE_REQUEST';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_FAILURE = 'DELETE_SERVICE_FAILURE';
export const SEARCH_SERVICES_REQUEST = 'SEARCH_SERVICES_REQUEST';
export const SEARCH_SERVICES_SUCCESS = 'SEARCH_SERVICES_SUCCESS';
export const SEARCH_SERVICES_FAILURE = 'SEARCH_SERVICES_FAILURE';

export const SERVICE_UNITS = [
  { value: 'hour', label: 'Hourly' },
  { value: 'day', label: 'Daily' },
  { value: 'week', label: 'Weekly' },
  { value: 'month', label: 'Monthly' },
  { value: 'year', label: 'Yearly' },
];
