export const CLEAR_CUSTOMERS_ERRORS = 'CLEAR_CUSTOMERS_ERRORS';
export const FETCH_CUSTOMER_REQUEST = 'FETCH_CUSTOMER_REQUEST';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMER_FAILURE';
export const FETCH_CUSTOMERS_REQUEST = 'FETCH_CUSTOMERS_REQUEST';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = 'FETCH_CUSTOMERS_FAILURE';
export const SAVE_CUSTOMER_REQUEST = 'SAVE_CUSTOMER_REQUEST';
export const SAVE_CUSTOMER_SUCCESS = 'SAVE_CUSTOMER_SUCCESS';
export const SAVE_CUSTOMER_FAILURE = 'SAVE_CUSTOMER_FAILURE';
export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE';
export const SEARCH_CUSTOMERS_REQUEST = 'SEARCH_CUSTOMERS_REQUEST';
export const SEARCH_CUSTOMERS_SUCCESS = 'SEARCH_CUSTOMERS_SUCCESS';
export const SEARCH_CUSTOMERS_FAILURE = 'SEARCH_CUSTOMERS_FAILURE';
export const FETCH_CUSTOMER_JOBS_REQUEST = 'FETCH_CUSTOMER_JOBS_REQUEST';
export const FETCH_CUSTOMER_JOBS_SUCCESS = 'FETCH_CUSTOMER_JOBS_SUCCESS';
export const FETCH_CUSTOMER_JOBS_FAILURE = 'FETCH_CUSTOMER_JOBS_FAILURE';
