import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  DISABLE_USER_REQUEST,
  DISABLE_USER_SUCCESS,
  DISABLE_USER_FAILURE,
  ENABLE_USER_REQUEST,
  ENABLE_USER_SUCCESS,
  ENABLE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  FETCH_TECH_JOBS_REQUEST,
  FETCH_TECH_JOBS_SUCCESS,
  FETCH_TECH_JOBS_FAILURE,
  SEARCH_TECHS_REQUEST,
  SEARCH_TECHS_SUCCESS,
  SEARCH_TECHS_FAILURE,
} from 'constants/techs';

const initialState = {
  isFetching: false,
  lastFetchAt: null,
  isSearching: false,
  lastSearchAt: null,
  errors: [],
  filter: '',
  user: {
    jobs: {
      rows: [],
      page: 0,
      pages: -1,
      pageSize: 10,
      lastFetchAt: null,
    },
  },
  rows: [],
  page: 0,
  pages: -1,
  pageSize: 10,
};

export default function techs(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case FETCH_USER_SUCCESS: {
      const { lastFetchAt } = state;
      const { update } = action.payload;
      return {
        ...state,
        isFetching: false,
        user: action.payload.user,
        errors: [],
        lastFetchAt: update ? new Date() : lastFetchAt,
      };
    }
    case FETCH_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case FETCH_USERS_SUCCESS: {
      const { lastFetchAt } = state;
      const { update } = action.payload;
      return {
        ...state,
        isFetching: false,
        rows: action.payload.rows,
        page: action.payload.page,
        pages: action.payload.pages,
        pageSize: action.payload.pageSize,
        filter: action.payload.filter,
        lastFetchAt: update ? new Date() : lastFetchAt,
        errors: [],
      };
    }
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case DISABLE_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case DISABLE_USER_SUCCESS: {
      const rows = state.rows.map(usr => {
        const user = usr;
        if (user.id === action.payload.userId) {
          user.status = 'disabled';
          user.disabledAt = new Date();
        }
        return user;
      });
      return {
        ...state,
        isFetching: false,
        rows,
        errors: [],
      };
    }
    case DISABLE_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case ENABLE_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case ENABLE_USER_SUCCESS: {
      const rows = state.rows.map(usr => {
        const user = usr;
        if (user.id === action.payload.userId) {
          user.status = 'active';
          user.disabledAt = null;
        }
        return user;
      });
      return {
        ...state,
        isFetching: false,
        rows,
        errors: [],
      };
    }
    case ENABLE_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case DELETE_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case DELETE_USER_SUCCESS: {
      const rows = state.rows.map(usr => {
        const user = usr;
        if (user.id === action.payload.userId) {
          user.status = 'deleted';
          user.deletedAt = new Date();
        }
        return user;
      });
      return {
        ...state,
        isFetching: false,
        rows,
        errors: [],
      };
    }
    case DELETE_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case FETCH_TECH_JOBS_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: [],
      };
    case FETCH_TECH_JOBS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: {
          ...state.user,
          jobs: {
            rows: action.payload.rows,
            page: action.payload.page,
            pages: action.payload.pages,
            pageSize: action.payload.pageSize,
            lastFetchAt: new Date(),
          },
        },
        errors: [],
      };
    case FETCH_TECH_JOBS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    case SEARCH_TECHS_REQUEST:
      return {
        ...state,
        isSearching: true,
        errors: [],
      };
    case SEARCH_TECHS_SUCCESS:
      return {
        ...state,
        isSearching: false,
        // techsSearch: action.payload.rows,
        lastSearchAt: new Date(),
        errors: [],
      };
    case SEARCH_TECHS_FAILURE:
      return {
        ...state,
        isSearching: false,
        errors: action.payload.errors,
      };
    default:
      return state;
  }
}
